"use client";
import { useState } from "react";
import DemoModal from "./DemoModal";
import PdfModal from "./PdfModal";
import * as pdfjsLib from "pdfjs-dist";
import { TextItem } from "pdfjs-dist/types/src/display/api";
import {
  DEMO_PDF_URL,
  LOCAL_STORAGE_DEMO_KEY,
} from "@/app/constants/constants";
import { toast, Toaster } from "sonner";
import { submitDemoJob } from "@/app/actions/demo/submitDemoJob";

const TryDemoNow = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
  const [pdfFile, setPdfFile] = useState(DEMO_PDF_URL);
  const [toastId, setToastId] = useState(null);

  const handleTryNow = () => {
    // Check localStorage for the demo key
    if (localStorage.getItem(LOCAL_STORAGE_DEMO_KEY)) {
      window.location.href = `/welcome/demo/${localStorage.getItem(
        LOCAL_STORAGE_DEMO_KEY
      )}`;
    } else {
      setIsModalOpen(true);
    }
  };

  const handleSubmitFile = (file) => {
    console.log("file", file);
    extractTextFromPDF(file).then(async (text) => {
      const githubLinks = findGitHubLinks(text);
      console.log("githubLinks", githubLinks);

      if (githubLinks.length == 0) {
        console.log("githubLinks", githubLinks);
        toast.error("No GitHub links found in the PDF");
        return;
      }
      // localStorage.setItem("SWELensDemoLink", "true");

      // Submit the GitHub links to the backend.
      console.log("SUBMITTING githubLinks", githubLinks);
      const toastId = toast.loading("Submitting GitHub links...");
      setToastId(toastId);

      const { error, data } = await submitDemoJob(githubLinks);
      if (error) {
        toast.error(error, { id: toastId });
        return;
      }

      if (data.demoAnalysisId != null) {
        localStorage.setItem(LOCAL_STORAGE_DEMO_KEY, data.demoAnalysisId);
      }

      toast.success("GitHub links submitted successfully!", {
        id: toastId,
      });

      // Redirect to the demo page
      window.location.href = `/welcome/demo/${data.demoAnalysisId}`;
    });
  };

  const extractTextFromPDF = async (file) => {
    const textContent = [];
    const typedArray = new Uint8Array(await file.arrayBuffer());
    pdfjsLib.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@3.5.141/build/pdf.worker.min.js`;
    const pdf = await pdfjsLib.getDocument(typedArray).promise;

    for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
      const page = await pdf.getPage(pageNum);
      const text = await page.getTextContent();
      text.items.forEach((item) => textContent.push((item as TextItem).str));
    }

    return textContent.join(" ");
  };

  const findGitHubLinks = (text) => {
    const githubRegex = /https?:\/\/(www\.)?github\.com\/[^\s]+/g;
    return text.match(githubRegex) || [];
  };

  return (
    <>
      <button className="btn btn-primary" type="button" onClick={handleTryNow}>
        Try Now
      </button>
      <DemoModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleSubmitFile}
        onViewSample={() => {
          setIsPdfModalOpen(true);
        }}
      />
      <PdfModal
        isOpen={isPdfModalOpen}
        onClose={() => setIsPdfModalOpen(false)}
        pdfFile={pdfFile}
      />
      <Toaster richColors={true} theme="dark" position="top-right" />
    </>
  );
};

export default TryDemoNow;
