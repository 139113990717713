import React, { useState } from "react";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { Worker } from "@react-pdf-viewer/core"; // To load the PDF worker
import { Viewer } from "@react-pdf-viewer/core"; // Main viewer
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
const PdfModal = ({ isOpen, onClose, pdfFile }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[2]">
      <div className="modal modal-open">
        <div className="modal-box max-w-4xl p-4">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-bold">View PDF</h3>
            <button
              className="btn btn-sm btn-circle btn-ghost"
              onClick={onClose}
            >
              ✕
            </button>
          </div>
          <div className="h-100 overflow-hidden">
            <Worker
              workerUrl={`https://unpkg.com/pdfjs-dist@3.5.141/build/pdf.worker.min.js`}
            >
              {pdfFile ? (
                <Viewer
                  fileUrl={pdfFile}
                  plugins={[defaultLayoutPluginInstance]}
                />
              ) : (
                <div className="flex items-center justify-center h-full">
                  <p className="text-gray-500">No PDF file selected</p>
                </div>
              )}
            </Worker>
          </div>
        </div>
        <div className="modal-backdrop" onClick={onClose}></div>
      </div>
    </div>
  );
};

export default PdfModal;
