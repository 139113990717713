"use client";
import { useRouter } from "next/navigation";

const OfferApiIntegration = () => {
  const handleContactClick = () => {
    window.open(
      "mailto:joseph@swelens.com?body=I%20want%20api%20access.%0AUse%20case%3A%20%3Cdescription%3E",
      "_blank"
    );
  };
  return (
    <>
      {/* <div className="flex flex-col items-center card bg-white w-[50%] h-auto rounded-lg shadow-md cursor-pointer hover:shadow-xl transition-shadow"> */}
      <div
        className="flex flex-col justify-center items-center bg-white p-4 rounded-lg hover:shadow-[0_0_15px_4px_rgba(255,255,255,0.6)] cursor-pointer"
        onClick={handleContactClick}
      >
        <h2 className="text-2xl font-bold mb-4 text-black">{`</> Connect through API`}</h2>
        <p className="text-black">
          {`Connect directly through our API. Send us an email to get started`}
        </p>
      </div>
      {/* </div> */}
    </>
  );
};

const Offer = ({}) => {
  const router = useRouter();

  const handleOffer = () => {
    router.push("/auth/signup"); // Navigate to the signup page
  };

  const handleClickEnterprise = () => {
    const subject = encodeURIComponent("Inquiry about the Enterprise Plan");
    const body = encodeURIComponent(
      "Best way to contact me: \n\nCompany: \n\nDescription: \n\n"
    );

    window.open(
      `mailto:joseph@swelen.com?subject=${subject}&body=${body}`,
      "_blank"
    );
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <h1 className="pb-4 text-3xl font-bold">How to Access</h1>

      <div className="flex flex-row md:flex-row flex-col gap-8 justify-center">
        {/* Free Tier Card */}
        <div
          className="card bg-white w-64 h-auto rounded-lg shadow-md border border-gray-200 cursor-pointer hover:shadow-[0_0_15px_4px_rgba(255,255,255,0.6)] transition-shadow"
          onClick={handleOffer}
        >
          <div className="card-body p-6 text-black">
            <div className="text-center">
              <h2 className="text-xl text-black font-semibold">Free Tier</h2>
              <p className="text-lg font-bold text-gray-800">$0/month</p>
            </div>
            <ul className="text-sm space-y-2 mt-4">
              <li className="text-black">
                <span className="text-green-500">✔</span> 10 Free Requests
              </li>
              <li className="text-black">
                <span className="text-green-500">✔</span> Pay-as-you-go model
              </li>
              <li className="text-black">
                <span className="text-red-500">✘</span> Queue Prioritization
              </li>
              <li className="text-black">
                <span className="text-green-500">✔</span> Project Summary
              </li>
              <li className="text-black">
                <span className="text-green-500">✔</span> Commit History
                Analysis
              </li>
              <li className="text-black">
                <span className="text-green-500">✔</span> Core Technologies &
                Techniques Analysis
              </li>
              <li className="text-black">
                <span className="text-green-500">✔</span> Code Review Analysis
              </li>
              <li className="text-black">
                <span className="text-green-500">✔</span> Persisting Analysis
              </li>
              <li className="text-black">
                <span className="text-red-500">✘</span> Shared Applicant
                Platform
              </li>
              <li className="text-black">
                <span className="text-red-500">✘</span> Custom Support
              </li>
              <li className="text-black">
                <span className="text-red-500">✘</span> API Integrations
              </li>
            </ul>
          </div>
        </div>

        {/* Pro Tier Card */}
        <div
          className="card bg-white w-64 h-auto rounded-lg shadow-md border border-gray-200 cursor-pointer hover:shadow-[0_0_15px_4px_rgba(255,255,255,0.6)] transition-shadow"
          onClick={handleOffer}
        >
          <div className="card-body p-6 text-black">
            <div className="text-center">
              <h2 className="text-xl text-black font-semibold flex justify-center items-center">
                Pro Tier
                <span className="ml-2 badge badge-primary">Popular</span>
              </h2>
              <p className="text-lg font-bold text-gray-800">$30/month</p>
            </div>
            <ul className="text-sm space-y-2 mt-4">
              <li className="text-black">
                <span className="text-green-500">✔</span> Unlimited Requests
              </li>
              <li className="text-black">
                <span className="text-green-500">✔</span> Queue Prioritization
              </li>
              <li className="text-black">
                <span className="text-green-500">✔</span> Project Summary
              </li>
              <li className="text-black">
                <span className="text-green-500">✔</span> Commit History
                Analysis
              </li>
              <li className="text-black">
                <span className="text-green-500">✔</span> Core Technologies &
                Techniques Analysis
              </li>
              <li className="text-black">
                <span className="text-green-500">✔</span> Code Review Analysis
              </li>
              <li className="text-black">
                <span className="text-green-500">✔</span> Persisting Analysis
              </li>
              <li className="text-black">
                <span className="text-red-500">✘</span> Shared Applicant
                Platform
              </li>
              <li className="text-black">
                <span className="text-red-500">✘</span> Custom Support
              </li>
              <li className="text-black">
                <span className="text-red-500">✘</span> API Integrations
              </li>
            </ul>
          </div>
        </div>

        {/* Enterprise Tier Card */}
        <div
          className="card bg-white w-64 h-auto rounded-lg shadow-md border border-gray-200 cursor-pointer hover:shadow-[0_0_15px_4px_rgba(255,255,255,0.6)] transition-shadow"
          onClick={handleClickEnterprise}
        >
          <div className="card-body p-6 text-black">
            <div className="text-center">
              <h2 className="text-xl text-black font-semibold">Enterprise</h2>
              <p className="text-lg font-bold text-gray-800">Custom Pricing</p>
            </div>
            <ul className="text-sm space-y-2 mt-4">
              <li className="text-black">
                <span className="text-green-500">✔</span> Unlimited Requests
              </li>
              <li className="text-black">
                <span className="text-green-500">✔</span> Queue Prioritization
              </li>
              <li className="text-black">
                <span className="text-green-500">✔</span> Project Summary
              </li>
              <li className="text-black">
                <span className="text-green-500">✔</span> Commit History
                Analysis
              </li>
              <li className="text-black">
                <span className="text-green-500">✔</span> Core Technologies &
                Techniques Analysis
              </li>
              <li className="text-black">
                <span className="text-green-500">✔</span> Code Review Analysis
              </li>
              <li className="text-black">
                <span className="text-green-500">✔</span> Persisting Analysis
              </li>
              <li className="text-black">
                <span className="text-green-500">✔</span> Shared Applicant
                Platform
              </li>
              <li className="text-black">
                <span className="text-green-500">✔</span> Custom Support
              </li>
              <li className="text-black">
                <span className="text-green-500">✔</span> API Integrations
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center w-[50%] p-8">
        <OfferApiIntegration />
      </div>
    </div>
  );
};

export default Offer;
