"use client";
import { useState } from "react";

const DemoModal = ({ isOpen, onClose, onConfirm, onViewSample }) => {
  const [isPdfFile, setIsPdfFile] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleOnClose = () => {
    onClose();
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];

    if (!file) {
      setIsPdfFile(false);
      setSelectedFile(null);
      return;
    }

    if (file.type === "application/pdf") {
      setIsPdfFile(true);
      setSelectedFile(file);
    } else {
      setIsPdfFile(false);
      setSelectedFile(null);
    }
  };

  const handleOnConfirm = () => {
    if (isPdfFile && selectedFile) {
      console.log("typeof selectedFile", typeof selectedFile);
      onConfirm(selectedFile);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[2]">
      <div className="modal modal-open">
        <div className="modal-box max-w-4xl text-center">
          <div className="flex w-full h-full">
            {/* Left Card */}
            <div className="flex flex-col justify-center items-center bg-base-300 rounded-box h-50 flex-grow w-1/3 p-4">
              <button
                className="btn btn-primary mb-2 btn-sm"
                onClick={() => {
                  window.location.href =
                    "/welcome/demo/446d5369-7382-4d1e-8701-3424d54222c7";
                }}
              >
                Use Sample Resume
              </button>
              <a
                href="#"
                className="text-blue-500 underline"
                onClick={onViewSample}
              >
                Show Sample Resume
              </a>
            </div>

            <div className="divider divider-horizontal">OR</div>

            {/* Right Card */}
            <div className="flex flex-col justify-center items-center bg-base-300 rounded-box h-50 flex-grow w-2/3 p-4">
              <label className="form-control w-full max-w-xs">
                <div className="label">
                  <span className="label-text">Pick a file</span>
                  <span className="label-text-alt">Must be a PDF</span>
                </div>
                <input
                  type="file"
                  className="file-input file-input-bordered file-input-primary w-full max-w-xs"
                  onChange={handleFileInputChange}
                />
              </label>
              <button
                className="btn btn-primary mt-2 btn-sm w-40"
                onClick={handleOnConfirm}
                disabled={!isPdfFile}
              >
                Start Analysis
              </button>
            </div>
          </div>
        </div>
        <div className="modal-backdrop" onClick={handleOnClose}></div>
      </div>
    </div>
  );
};

export default DemoModal;
